/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { KROK1, KROK2, KROK3, PODSUMOWANIE } from "../routes";
import { RootState } from "../store";

const ROUTES = [KROK1, KROK2, KROK3, PODSUMOWANIE];

function withRedirect<T>(Component: React.ComponentType<T>) {
  return (props: any) => {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = useSelector((state: RootState) => state.app.data);

    useEffect(() => {
      const foundIndex = ROUTES.findIndex((item) => item === location.pathname);

      if (
        // path not found
        foundIndex === -1 ||
        (location.pathname !== KROK1 && foundIndex !== -1 && !data)
      ) {
        naviagte(KROK1);
      }
    }, [location, data]);

    return <Component {...props} />;
  };
}

export default withRedirect;
