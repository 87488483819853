/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect } from "react";
import { getAddressByNip } from "../api/manager";
import { useDispatch } from "react-redux";
import { setAddress } from "../reducers/app";

const useSetAddress = (nip: string, onSetNip?: () => void) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    (async () => {
      const address = await getAddressByNip(nip as string);

      dispatch(setAddress({ address: address || "" }));
      if (onSetNip) onSetNip();
    })();
  }, []);
};

export default useSetAddress;
