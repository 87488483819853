import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import IconText from "./IconText";

interface Props {
  checked: boolean;
  check: () => void;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  checkbox: {
    padding: "0 !important",
  },
});

const CheckText = ({ check, checked, children }: Props) => {
  const classes = useStyles();
  return (
    <IconText
      icon={
        <Checkbox
          classes={{ root: classes.checkbox }}
          {...{ checked }}
          onChange={check}
        />
      }
    >
      {children}
    </IconText>
  );
};

export default CheckText;
