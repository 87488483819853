import axios from "axios";

const api = axios.create({
  baseURL: "https://manager.sygnalisci.app/api/",
});

export const getAddressByNip = async (nip: string): Promise<null | string> => {
  try {
    const { data } = await api.get("SygInstances/byNip", {
      params: { nip },
    });

    if (!data) throw Error("Could not find NIP");

    return data;
  } catch {
    return null;
  }
};
