/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getAddressByNip } from "./api/manager";
import Container from "./components/Container";
import withRedirect from "./hocs/withRedirect";
import usePreventLeaving from "./hooks/usePreventLeaving";

import { updateKrok1 } from "./reducers/app";
import { KROK2 } from "./routes";

const useStyle = makeStyles({
  input: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const Krok1 = () => {
  const classes = useStyle();
  usePreventLeaving();
  const [nip, setNip] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [noNip, setNoNip] = useState(false);
  const [loading, setLoading] = useState(false);
  const nextPage = useCallback(async () => {
    const emptyNip = !nip || (nip && nip.length === 0);
    if (!emptyNip) {
      setLoading(true);
      const address = await getAddressByNip(nip);

      setLoading(false);

      if (address) {
        dispatch(updateKrok1({ nip, address }));
        navigate(KROK2);
        return;
      } else {
        toast.error("Błędny NIP");
        setNoNip(true);
      }
    } else {
      toast.error("Podaj NIP");
      setNoNip(true);
    }
  }, [noNip, nip]);

  return (
    <Container
      title="Formularz sygnalistów"
      description="Krok 1 - wpisz NIP podmiotu, którego dotyczy zgłoszenie"
      nextPage={nextPage}
      cancel={() => {}}
      loading={loading}
      noCancel
    >
      <Input
        id="nip"
        error={noNip}
        value={nip}
        onChange={(e) => setNip(e.target.value)}
        classes={{ input: classes.input }}
        placeholder="NIP z myślnikami lub bez, np 123-45-67-890"
      />
    </Container>
  );
};

export default withRedirect(Krok1);
