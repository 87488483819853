import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { MOBILE_QUERY, palette } from "../../theme";
import { LogAttachment, LogData } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
  log: LogData;
  attachments: { [key: string]: LogAttachment };
  accessNumber: number | string;
}

const useStyles = makeStyles({
  date: {
    color: "#bdbdbd !important",
    fontSize: "14px !important",
    [MOBILE_QUERY]: {
      fontSize: "12px",
    },
  },
  content: {
    fontSize: "18px",
    [MOBILE_QUERY]: {
      fontSize: "15px",
    },
  },
});

const Log = ({
  log: { date, attachmentIds, fullMessage },
  attachments,
  accessNumber,
}: Props) => {
  const classes = useStyles();
  const address = useSelector((state: RootState) => state.app.address);
  const viewFile = async (fileId: string | number) => {
    // Change this to use your HTTP client
    console.log(address);
    fetch(
      `https://${address}/api/reportSubmission/getFile?AttachmentId=${fileId}`,
      {
        headers: { AccessNumber: `${accessNumber}` },
      }
    ) // FETCH BLOB FROM IT
      .then((response) => response.blob())
      .then((blob) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        var _url = window.URL.createObjectURL(blob);
        window?.open(_url, "_blank")?.focus(); // window.open + focus
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Typography className={classes.date}>
        {moment(date).format("YYYY-MM-DD hh:mm")}
      </Typography>
      <Typography className={classes.content} fontWeight="normal">
        {fullMessage}
      </Typography>
      {attachmentIds && attachmentIds.length > 0 && (
        <div className="flex-row flex-align">
          <Typography>{"Załączone pliki: "}</Typography>
          {attachmentIds.map((attachmentId) => {
            const f = attachments[attachmentId];
            return (
              <div
                key={attachmentId}
                onClick={() => viewFile(attachmentId)}
                style={{
                  color: palette.text.primary,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {f.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Log;
