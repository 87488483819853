import React from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

interface SelectValue {
  id: string;
  name: string;
}

interface Props {
  data: SelectValue[];
  value: string | null;
  onChange: (v: string) => void;
  error: boolean;
  loading?: boolean;
}

const useStyles = makeStyles({
  progress: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Dropdown = ({ data, value, onChange, error, loading }: Props) => {
  const classes = useStyles();
  return (
    <FormControl variant="standard">
      <InputLabel>Wybierz rodzaj zgłoszenia</InputLabel>

      <Select
        error={error}
        value={value}
        variant="standard"
        onChange={(e) => {
          const selectedId = e.target.value as string;
          onChange(selectedId);
        }}
      >
        {!loading ? (
          data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <div className={classes.progress}>
            <CircularProgress size={30} />
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
