import toast from "react-hot-toast";
import { FormCheckerData } from "../types";

export const isEmptyField = (value: string | null | undefined) =>
  !value || (value && value.length === 0);

export const checkForm = (forms: FormCheckerData[]) => {
  for (let i = 0; i < forms.length; i++) {
    const data = forms[i];
    if (!data.value) {
      toast.error(data.errorTitle);
      return data.id;
    }
  }

  return null;
};

export const getValue = <T>(v: any, defValue: T): T => {
  return v ? v : defValue;
};
