import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { palette } from "../theme";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

interface Props {
  title: string;
  checked: boolean;
  style?: React.CSSProperties | undefined;
  check: () => void;
}

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
});

const CheckButton = ({ title, checked, check, style }: Props) => {
  const classes = useStyle();
  const disabledColor = checked ? palette.text.primary : "rgba(0,0,0,0.12)";
  const iconStyle = {
    color: disabledColor,
    fontSize: "20px",
  };
  return (
    <Button
      style={{ borderColor: disabledColor, ...style }}
      classes={{ root: classes.container }}
      onClick={check}
      variant="outlined"
    >
      <div className={classes.row}>
        {checked ? (
          <RadioButtonChecked sx={iconStyle} />
        ) : (
          <RadioButtonUnchecked sx={iconStyle} />
        )}
        <Typography
          style={{
            color: disabledColor,
            marginLeft: "5px",
          }}
        >
          {title}
        </Typography>
      </div>
    </Button>
  );
};

export default CheckButton;
