/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { MOBILE_QUERY, palette } from "../theme";
import { loginByPin } from "../api";
import { EDIT } from "../routes";
import toast from "react-hot-toast";
import useSetAddress from "../hooks/useSetAddress";
import { getPaste } from "../helpers/getPaste";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "42rem",
    [MOBILE_QUERY]: {
      width: "90%",
    },
  },
  title: {
    fontWeight: "bold",
  },
  makeSpace: {
    marginTop: "1.5rem !important",
  },
  submit: {
    display: "flex",
    marginTop: "1.5rem !important",
    padding: "10px 0",
    width: "100%",
    height: "4rem",
    [MOBILE_QUERY]: {
      width: "90vw",
    },
  },
  progress: {
    color: palette.text.secondary,
    marginRight: "1rem",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EditLogin = () => {
  const classes = useStyles();
  const { id } = useParams();
  const query = useQuery();
  const nip = query.get("nip");
  const [pin, setPin] = useState("");

  const disabled = !(pin && pin.length === 4);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useSetAddress(nip as string);

  const login = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const { data, msg } = await loginByPin(id || "", pin);
      setLoading(false);
      if (!data || msg) {
        setPin("");
        pinInputRef.current?.clear();
        toast.error("Błędny PIN");
        return;
      }

      navigate(EDIT, { state: { data, pin, nip } });
    },
    [id, pin]
  );
  const pinInputRef = useRef<PinInput>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (inputContainerRef.current)
      inputContainerRef.current.addEventListener("paste", (event) => {
        setPin(getPaste(event));
      });
  }, []);
  return (
    <form className={classes.container} onSubmit={login}>
      <div className={classes.container}>
        <Typography
          style={{ color: palette.text.secondary }}
          fontWeight="bold"
          variant="h4"
          align="center"
        >
          Aby otworzyć to zgłoszenie musisz podać kod PIN
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          className={classes.makeSpace}
        >
          Znajdziesz go w mailu, który otrzymałeś na podany podczas zgłoszenia
          adres lub w pliku pdf, który był do pobrania jako potwierdzenie
          zgłoszenia.
        </Typography>
        <div className={classes.formContainer}>
          <div ref={inputContainerRef} className={classes.makeSpace}>
            <PinInput
              ref={pinInputRef}
              length={4}
              initialValue=""
              //   secret
              onChange={(value, index) => {
                setPin(value);
              }}
              type="numeric"
              inputMode="number"
              style={{
                padding: "10px 0",
                margin: "0px !important",
                gap: "1rem",
              }}
              inputStyle={{ borderColor: "red", margin: "0px !important" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <Button
            disabled={disabled}
            type="submit"
            variant="contained"
            className={classes.submit}
            style={{
              backgroundColor: palette.text.primary,
              color: palette.text.secondary,
              opacity: disabled ? 0.2 : 1,
            }}
          >
            {loading && (
              <CircularProgress size={25} className={classes.progress} />
            )}
            <Typography style={{ textTransform: "none" }} color="inherit">
              Przejdź dalej
            </Typography>
          </Button>
        </div>
      </div>
      <Link
        href="https://www.investit.dev/polityka-prywatnosci-2/"
        target="_blank"
        color={palette.text.primary}
        style={{ marginTop: "3.5rem" }}
      >
        Dlaczego zabezpieczamy te informacje?
      </Link>
    </form>
  );
};

export default EditLogin;
