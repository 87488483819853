export interface KrokData {
  page: number;
  prevPage: () => void;
  nextPage: (action: () => void) => () => void;
}

export interface FormCheckerData {
  value: null | string | boolean;
  errorTitle: string;
  id: string;
}

export interface UserFile {
  name: string;
  uri: string;
}

export interface Comment {
  content: string;
  files: UserFile[];
  date: string;
}

export enum Closure {
  ANINIMOWE = 0,
  JAWNE,
}

export interface ReportParams {
  titleId: number | string;
  closure: Closure;
  fullName?: string;
  contents: string;
  attachments: FormFile[];
}

export interface ReportSuccess {
  id: string;
  accessNumber: string;
}

export interface ValidNipResponse {
  success: boolean;
  errorMessage: string;
}

export interface ReportTitle {
  name: string;
  id: string;
}

export interface FormFile {
  name: string;
  contentsBase64: string;
  uploadDate: Date;
}

export type LogAttachment = {
  id: string;
} & FormFile;

export interface LogData {
  attachmentIds: number[];
  date: Date;
  fullMessage: string;
  id: number;
  logType: string;
  message: null;
  newlyAssignedPersonName: null;
  reportId: number;
}

export interface EditData {
  logs: LogData[];
  contents: string;
  id: string;
  accessNumber: string;
  attachments: LogAttachment[];
}

export interface EditRouteState {
  data: EditData;
  nip: string;
  pin: string;
}

export interface NewCommentData {
  reportId: number | string;
  accessNumber: string;
  commentContents: string;
  attachments: CommentAttachment[];
}

export interface CommentAttachment {
  contentsBase64: string;
  name: string;
}
