/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from "react";
import { makeStyles } from "@mui/styles";
import Container from "./components/Container";
import withRedirect from "./hocs/withRedirect";
import { Button, Link, Typography } from "@mui/material";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import { KROK1 } from "./routes";
import { generatePdf } from "./helpers/pdf";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "./reducers/app";
import { RootState } from "./store";

const useStyle = makeStyles({
  link: {
    fontFamily: "Manrope",
    color: `#007bbd !important`,
    wordBreak: "break-word",
  },
  text: {
    marginTop: "1rem",
  },
  pin: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  controll: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: "0.8rem !important",
  },
});

const Podsumowanie = (props: any) => {
  const classes = useStyle();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nip = useSelector((state: RootState) => state.app.data?.nip);
  useLayoutEffect(() => {
    window.addEventListener("popstate", () => {
      navigate(KROK1);
      dispatch(reset());
    });
  }, []);
  const notificationId = location.state?.id;
  // const date =
  //   location.state && location.state.date ? location.state.date : null;
  const notificationLink = `${document.location.origin}/zgloszenia/${notificationId}?nip=${nip}`;
  const pin = location.state && location.state.pin ? location.state.pin : null;
  return (
    <Container
      noControll
      title={`Twoje zgłoszenie #${notificationId} z dn.${moment().format(
        "DD-MM-YYYY"
      )} zostało wysłane`}
      nextPage={() => {}}
      cancel={() => {}}
    >
      <Typography variant="subtitle1">
        Na podany przez Ciebie adres email wysłaliśmy link, pod którym możesz
        uzupełniać dane i korespondować z organizacją, której dotyczy
        zgłoszenie.
      </Typography>

      <Typography className={classes.text} variant="subtitle1">
        Znajdziesz je również ponizej:
      </Typography>
      <Link
        fontWeight="bold"
        color="inherit"
        className={classes.link}
        href={notificationLink}
        target="_blank"
      >
        {notificationLink}
      </Link>
      <Typography variant="subtitle1" className={classes.text}>
        Aby je otworzyć musisz uzyc kodu PIN.
      </Typography>
      <Typography
        variant="h3"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
        className={classes.pin}
      >
        {`Twój kod PIN to: ${pin}`}
      </Typography>
      <div className={classes.controll}>
        <Button
          type="submit"
          variant="outlined"
          onClick={() => {
            generatePdf({ id: notificationId, pin, nip });
          }}
        >
          Pobierz potwierdzenie w PDF
        </Button>

        <Button
          className={classes.button}
          variant="text"
          onClick={() => navigate(KROK1)}
        >
          Wróć do strony głównej
        </Button>
      </div>
    </Container>
  );
};

export default withRedirect(Podsumowanie);
