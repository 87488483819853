import React, { ReactNode } from "react";
import { makeStyles } from "@mui/styles";

interface Props {
  icon: React.ReactNode;
  children: ReactNode;
}

const useStyle = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem",
  },
  icon: {
    display: "flex",
    minWidth: "50px",
    height: "25px",
  },
});

const IconText = ({ icon, children }: Props) => {
  const classes = useStyle();
  return (
    <div className={classes.row}>
      <div className={classes.icon}>{icon}</div>
      {children}
    </div>
  );
};

export default IconText;
