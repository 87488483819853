/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
import api from "../api";

const useApiFetch = <T>(
  endpoint: string,
  params?: { [key: string]: string }
): [T | null, boolean, null | string] => {
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<null | T>(null);
  useLayoutEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await api.get(endpoint, { params });
        const { success } = data;
        if (!success) throw new Error("Api erorr");

        setData(data?.data || []);
      } catch {
        setError("Coś poszło nie tak");
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    fetch();
  }, []);

  return [data, loading, error];
};

export default useApiFetch;
