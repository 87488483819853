import { createTheme } from "@mui/material/styles";

export const MOBILE_QUERY = "@media (max-width:850px)";

export const palette = {
  accent: "#ffcf27",
  text: {
    primary: "#313131",
    secondary: "#fff",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#313131",
    },
  },
  typography: {
    fontFamily: "Manrope",
    subtitle1: {
      fontWeight: "normal",
      fontSize: "1.2rem",
      [MOBILE_QUERY]: {
        fontSize: "1rem",
      },
    },
    subtitle2: {
      fontWeight: "bold",
      fontSize: "1.2rem",
      [MOBILE_QUERY]: {
        fontSize: "1rem",
      },
    },
    caption: {
      fontSize: "1rem",
      [MOBILE_QUERY]: { fontSize: "0.8rem" },
    },

    h3: {
      fontWeight: "bold",
      fontSize: "2.7rem",
      [MOBILE_QUERY]: { fontSize: "1.7rem" },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        style: {
          color: palette.text.primary,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        style: {
          fontSize: "1rem",
          marginTop: "1rem",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        style: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
          borderWidth: "2px",
        },
      },
    },
  },
});

export default theme;
