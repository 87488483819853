import html2pdf from "html2pdf.js";
import moment from "moment";

const getWithTemplate = ({ id, pin, url, title }) => `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title>${title}</title>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap"
      rel="stylesheet"
    />

    <style>
      body {
        padding: 0;
        margin: 0;
        background-color: #ffcf27;
        font-family: Manrope !important;
        display: flex;
        justify-content: center;
      }

      .container {

        display: flex;
        flex-direction: column;
      }
      h1 {
        font-size: 46px;
      }
      p {
        font-size: 18px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <h1>Twoje zgłoszenie #${id} z dn.${moment().format(
  "DD-MM-YYYY"
)} zostało wysłane</h1>
      <p>
        Na podany przez Ciebie adres email wysłaliśmy link, pod którym możesz
        uzupełniać dane i korespondować z organizacją, której dotyczy
        zgłoszenie.
      </p>
      <p>
        Znajdziesz je również poniżej:
       
      </p>
      <a href="${url}">${url}</a>
      <p>Aby je otworzyć musisz użyć kodu PIN.</p>
      <h1>Twój kod PIN to: ${pin}</h1>
    </div>
  </body>
</html>

`;

export const generatePdf = (data) => {
  const fileName = `potwierdzenie ${moment().format("DD-MM-YYYY mm:ss")}`;
  var opt = {
    margin: 1,
    filename: fileName,
    // image: { type: "jpeg", quality: 0.98 },
    // html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };
  const notificationUri = `${document.location.origin}/zgloszenia/${data.id}?nip=${data.nip}`;
  // New Promise-based usage:
  html2pdf()
    .set(opt)
    .from(
      getWithTemplate({
        ...data,
        url: notificationUri,
        fileName,
        title: fileName,
      })
    )
    .save();
};
