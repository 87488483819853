import axios from "axios";
import store from "../store";
import {
  EditData,
  NewCommentData,
  ReportParams,
  ReportSuccess,
  ValidNipResponse,
} from "../types";

const api = axios.create({
  baseURL: "",
});

api.interceptors.request.use((config) => {
  const state = store.getState();
  config.baseURL = `https://${state.app.address}/api/`;

  return config;
});

export default api;

export const VALID_NIP = "ReportSubmission/validNIP";
export const REPORT_TITLES = "ReportSubmission/reportTitles";
export const POST_REPORT = "reportSubmission/postReport";
export const GET_REPORT = "ReportSubmission/getReport";
export const POST_COMMENT = "ReportSubmission/postComment";

type ApiFetchReturn<T> = { data: T | null; success: boolean };
type QueryParams = {
  [key: string]: string | number;
};
export const apiFetch = async <T>(
  query: string,
  queryParams: QueryParams
): Promise<ApiFetchReturn<T>> => {
  try {
    const { data } = await api.get(query, { params: queryParams });

    return { success: true, data };
  } catch (e) {
    console.log(e);
    return { success: false, data: null };
  }
};

interface ResponseMessage<T> {
  msg: string | null;
  data: T;
}

export const validateNIP = async (
  nip: string
): Promise<ResponseMessage<boolean>> => {
  const { data } = await apiFetch<ValidNipResponse>(VALID_NIP, {
    nip,
  });

  const success = data && data.success;
  return {
    msg: success ? null : "Ten numer NIP nie jest zarejestrowany",
    data: Boolean(success),
  };
};

export const postReport = async (
  formData: ReportParams
): Promise<ResponseMessage<ReportSuccess | null>> => {
  try {
    const { data } = await api.post(POST_REPORT, { ...formData });

    if (!data.success) {
      return { msg: data.errorMessage, data: null };
    }
    return { msg: null, data: data.data };
  } catch {
    return { msg: "Pliki są zbyt duże", data: null };
  }
};

export const loginByPin = async (
  id: string,
  pin: string
): Promise<ResponseMessage<EditData | null>> => {
  try {
    const { data } = await api.get(GET_REPORT, {
      params: { Id: id },
      headers: { AccessNumber: pin },
    });

    return { data: data.data, msg: null };
  } catch {
    return { data: null, msg: "Niepoprawny pin" };
  }
};

export const postComment = async (
  commentData: NewCommentData
): Promise<ResponseMessage<boolean>> => {
  try {
    const { data } = await api.post(
      POST_COMMENT,
      {
        ...commentData,
      },
      { headers: { AccessNumber: commentData.accessNumber } }
    );

    return { data: data.success, msg: null };
  } catch (e) {
    console.log(e);
    return { data: false, msg: "Niepoprawny pin" };
  }
};
