/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Input, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import AttachmentButton from "../components/AttachmentButton";
import Container from "../components/Container";
import { EditData, EditRouteState, FormFile, LogAttachment } from "../types";
import { useLocation } from "react-router";
import Log from "./components/Log";
import { loginByPin, postComment } from "../api";
import toast from "react-hot-toast";
import useSetAddress from "../hooks/useSetAddress";

const useStyles = makeStyles({
  send: {
    marginTop: "3rem",
  },
  loading: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapAttachements = (attachments: LogAttachment[]) => {
  const data: { [key: string]: LogAttachment } = {};

  attachments.forEach((att) => {
    data[att.id] = att;
  });

  return data;
};

const Edit = () => {
  const [selectedFiles, setSelectedFiles] = useState<FormFile[]>([]);
  const { state } = useLocation();
  const [content, setContent] = useState<string>("");
  const stateData = state as EditRouteState;
  const [adding, setAdding] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [currentData, setCurrentData] = useState<Partial<EditData>>({
    logs: [],
    attachments: [],
  });
  const { nip } = stateData;
  const { accessNumber, id } = stateData.data;
  const { logs, attachments } = currentData;

  const addComment = useCallback(async () => {
    if (!content || (content && content.length === 0)) {
      toast.error("Dodaj komentarz");
      return;
    }

    setAdding(true);
    const { data: success } = await postComment({
      attachments: selectedFiles,
      commentContents: content,
      reportId: id,
      accessNumber,
    });

    setAdding(false);
    if (!success) {
      toast.error("Coś poszło nie tak");
      return;
    }
    toast.success("Wysłano");

    refetch();
  }, [selectedFiles, content, id, accessNumber]);

  const refetch = useCallback(async () => {
    setReloading(true);
    const { data } = await loginByPin(id || "", accessNumber);

    setContent("");
    setSelectedFiles([]);
    if (!data) {
      toast.error("Coś poszło nie tak");
      return;
    }
    setReloading(false);

    setCurrentData(data);
  }, [accessNumber, id]);
  useSetAddress(nip, refetch);

  const classes = useStyles();
  return (
    <Container noControll>
      {reloading || null ? (
        <div className={classes.loading}>
          <CircularProgress size={40} />
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {logs &&
            logs.map((log) => (
              <Log
                log={log}
                accessNumber={accessNumber}
                attachments={mapAttachements(attachments || [])}
              />
            ))}
          <div style={{ marginTop: "4rem" }}>
            <Input
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Tutaj wpisz swoje uwagi komentarz"
            />
            <div style={{ marginBottom: "1rem" }}>
              <AttachmentButton
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
          </div>
          <Button
            disabled={adding}
            className={classes.send}
            variant="outlined"
            onClick={addComment}
          >
            {adding ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              <Typography style={{ textTransform: "none" }}>
                Wyślij zgłoszenie
              </Typography>
            )}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default Edit;
