/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Container from "./components/Container";
import withRedirect from "./hocs/withRedirect";
import Dropdown from "./components/Dropdown";
import { Input, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import CheckButton from "./components/CheckButton";
import CheckText from "./components/CheckText";
import { useDispatch, useSelector } from "react-redux";
import { setKrok2 } from "./reducers/app";
import { useNavigate } from "react-router-dom";
import { KROK3 } from "./routes";
import { checkForm, getValue, isEmptyField } from "./helpers";
import { FormCheckerData, FormFile, ReportTitle } from "./types";
import { RootState } from "./store";
import AttachmentButton from "./components/AttachmentButton";
import usePreventLeaving from "./hooks/usePreventLeaving";
import useApiFetch from "./hooks/useApiFetch";
import { REPORT_TITLES } from "./api/index";

const useStyle = makeStyles({
  recaptcha: {
    marginTop: "25px",
    justifyContent: "flex-end",
    marginBottom: "15px",
    display: "flex",
    width: "100%",
  },
  input: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  label: {
    marginTop: "2rem !important",
  },
  checkButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "1rem",
  },
  bottom: {
    marginTop: "1.5rem",
  },
});

const ERRORS = {
  type: "type_error",
  comment: "comment_error",
  name: "name_error",
  permission: "permission_error",
};

const TITLE1 =
  "Krok 2 – opisz problem możliwie dokładnie, pamiętając o nieujawnianiu swoich danych";
const TITLE2 =
  "Krok 2 – opisz problem możliwie dokładnie, pamiętając o uzupełnieniu twoich danych, żebyśmy mogli się z Tobą skontaktować";

const Krok2 = () => {
  usePreventLeaving();
  const [typeId, setTypeId] = useState("");
  const classes = useStyle();
  const [anonymous, setAnonymous] = useState(true);
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [errorId, setErrorId] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FormFile[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nip = useSelector((state: RootState) => state.app.data?.nip || "");
  const [titlesData, titlesLoading] = useApiFetch<ReportTitle[]>(
    REPORT_TITLES,
    {
      Nip: nip,
    }
  );
  const titles = titlesData || [];
  const nextPage = useCallback(() => {
    const primaryFormFilled =
      permissionChecked && typeId && !isEmptyField(comment);
    if (
      (primaryFormFilled && anonymous) ||
      (primaryFormFilled && !anonymous && !isEmptyField(name))
    ) {
      const found = titles ? titles.find((title) => title.id === typeId) : null;
      const type = found ? found : null;
      dispatch(
        setKrok2({ name, comment, type, files: selectedFiles, anonymous })
      );
      navigate(KROK3);

      return;
    }

    const forms: FormCheckerData[] = [
      {
        value: permissionChecked,
        errorTitle: "Zaznacz wymagane pola",
        id: ERRORS.permission,
      },
      {
        value: typeId,
        errorTitle: "Wybierz rodzaj zgłoszenia",
        id: ERRORS.type,
      },
      {
        value: !isEmptyField(comment),
        errorTitle: "Podaj uwagi/komentarz ",
        id: ERRORS.comment,
      },
      {
        value: !anonymous && !isEmptyField(name),
        errorTitle: "Podaj imię i nazwisko ",
        id: ERRORS.name,
      },
    ];

    const errorFormId = checkForm(forms);

    if (errorFormId) setErrorId(errorFormId);
  }, [
    comment,
    name,
    anonymous,
    permissionChecked,
    typeId,
    selectedFiles,
    titles,
  ]);

  const data = useSelector((state: RootState) => state.app.data);
  useEffect(() => {
    if (data) {
      setComment(getValue<string>(data.comment, ""));
      setName(getValue(data.name, ""));
      setSelectedFiles(getValue(data.files, []));
      setTypeId(getValue(data?.type?.id, ""));
      setAnonymous(data?.anonymous);
    }
  }, [data]);

  return (
    <Container
      title="Formularz sygnalistów"
      description={anonymous ? TITLE1 : TITLE2}
      nextPage={nextPage}
      cancel={() => {}}
    >
      <Dropdown
        loading={titlesLoading}
        error={errorId === ERRORS.type}
        data={titles as { name: string; id: string }[]}
        value={typeId}
        onChange={setTypeId}
      />
      <Typography variant="subtitle1" className={classes.label}>
        W jaki sposób chcesz zarejestrować swoje zgłoszenie?
      </Typography>
      <div className={classes.checkButton}>
        <CheckButton
          style={{ marginRight: "10px" }}
          checked={anonymous}
          title="Anonimowe"
          check={() => setAnonymous(!anonymous)}
        />
        <CheckButton
          style={{ marginLeft: "10px" }}
          checked={!anonymous}
          title="Jawnie"
          check={() => setAnonymous(!anonymous)}
        />
      </div>
      {!anonymous && (
        <Input
          error={errorId === ERRORS.name}
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Imię i nazwisko"
        />
      )}

      <TextField
        error={errorId === ERRORS.comment}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        placeholder="Tutaj wpisz swoje uwagi / komentarz"
        multiline
        maxRows={10}
        variant="standard"
      />

      <div className={classes.bottom}>
        <AttachmentButton
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        <CheckText
          checked={permissionChecked}
          check={() => setPermissionChecked(!permissionChecked)}
        >
          Oświadczam, że podane przeze mnie dane są prawdziwe, że nie wynikają z
          pobudek osobistych oraz że dołożę wszelkich starań aby wyjaśnić
          wszelkie ewentualne wątpliwości i pytania odnoszące się do zgłaszanego
          przeze mnie problemu.
        </CheckText>
      </div>
    </Container>
  );
};

export default withRedirect(Krok2);
