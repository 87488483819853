import { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import { PODSUMOWANIE } from "../routes";

const onLeave = (e: any) => {
  e.preventDefault();
  e.returnValue = "alert";
};

const usePreventLeaving = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === PODSUMOWANIE)
      window.removeEventListener("beforeunload", onLeave);
  }, [location]);
  useLayoutEffect(() => {
    window.addEventListener("beforeunload", onLeave);

    return () => {
      window.removeEventListener("beforeunload", onLeave);
    };
  }, []);
};

export default usePreventLeaving;
