/* eslint-disable react-hooks/exhaustive-deps */
import { Attachment } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { FormFile } from "../types";
import AttachmentItem from "./AttachmentItem";
import IconText from "./IconText";

interface Props {
  selectedFiles: FormFile[];
  setSelectedFiles: (file: FormFile[]) => void;
}

const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const AttachmentButton = ({ selectedFiles, setSelectedFiles }: Props) => {
  const onFileAdd = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e && e.target && e.target.files && e.target.files.length > 0) {
        const addedFilesAsync = Array.prototype.map.call(
          e.target.files,
          async (file) => {
            const newFile = file as File;

            const base64 = await toBase64(newFile);
            return {
              contentsBase64: base64,
              name: newFile.name,
              uploadDate: new Date(),
            };
          }
        ) as Promise<FormFile>[];
        const addedFiles = await Promise.all(addedFilesAsync);
        setSelectedFiles([...selectedFiles, ...addedFiles]);
      }
    },
    [selectedFiles]
  );
  const removeFile = useCallback(
    (index: number) => () => {
      if (index > -1) {
        const copy = [...selectedFiles];
        copy.splice(index, 1);
        setSelectedFiles(copy);
      }
    },
    [selectedFiles]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <IconText icon={<Attachment />}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "flex-start",
        }}
      >
        <Button
          style={{ padding: 0, textTransform: "none" }}
          variant="text"
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}
        >
          <Typography>Załączniki: dodaj załączniki</Typography>

          <input
            onChange={onFileAdd}
            multiple
            hidden
            ref={inputRef}
            type="file"
          />
        </Button>
        <div
          style={{
            flex: 1,
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {selectedFiles.map((f, index) => (
            <AttachmentItem
              key={f.name}
              name={f.name}
              onRemove={removeFile(index)}
            />
          ))}
        </div>
      </div>
    </IconText>
  );
};

export default AttachmentButton;
