/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import Container from "./components/Container";
import withRedirect from "./hocs/withRedirect";
import {
  Button,
  CircularProgress,
  Divider,
  Input,
  Typography,
} from "@mui/material";
import { palette } from "./theme";
import CheckText from "./components/CheckText";
import { useDispatch, useSelector } from "react-redux";
import { setKrok3 } from "./reducers/app";
import { useNavigate } from "react-router";
import { KROK2, PODSUMOWANIE } from "./routes";
import { checkForm, isEmptyField } from "./helpers";
import { RootState } from "./store";
import validator from "email-validator";
import toast from "react-hot-toast";
import usePreventLeaving from "./hooks/usePreventLeaving";
import ReCAPTCHA from "react-google-recaptcha";
import { postReport } from "./api/index";
import { Closure } from "./types";

const useStyle = makeStyles({
  textContainer: {
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    marginRight: "35px !important",
    backgroundColor: palette.accent,
    borderRightWidth: "3px !important",
  },
  title: {
    marginTop: "1rem !important",
    marginBottom: "1rem !important",
  },
  email: {
    marginTop: "2rem !important",
  },
  permissions: {
    marginTop: "1rem",
  },
  controll: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem !important",
  },
  button: {
    marginTop: "0.8rem !important",
    textTransform: "none",
  },
  submitButton: {
    WebkitBoxShadow: "0px 7px 26px -23px rgba(66, 68, 90, 1) !important",
    MozBoxShadow: "0px 7px 26px -23px rgba(66, 68, 90, 1) !important",
    boxShadow: "0px 7px 26px -23px rgba(66, 68, 90, 1) !important",
  },
  recaptcha: {
    marginTop: "25px",
    justifyContent: "flex-end",
    marginBottom: "15px",
    display: "flex",
    width: "100%",
  },
});

const PERM1 = "perm1";
const PERM2 = "perm2";
const PERM3 = "perm3";

const ERRORS = {
  email: "email_error",
  permissions: "permission_error",
  recaptcha: "recaptcha",
  api: "api_error",
};

const Krok3 = () => {
  usePreventLeaving();
  const classes = useStyle();
  const [permissions, setPermissions] = useState<{ [permId: string]: boolean }>(
    { [PERM1]: false, [PERM2]: false, [PERM3]: false }
  );

  const checkPermission = useCallback(
    (permId: string) => () => {
      setPermissions((prev) => ({ ...prev, [permId]: !prev[permId] }));
    },
    []
  );
  const formData = useSelector((state: RootState) => state.app.data);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorId, setErrorId] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);

  const onSend = useCallback(
    async (id: string, pin: string) => {
      setSaving(false);
      toast.success("Wysłano twoje zgłoszenie");
      setTimeout(() => {
        dispatch(setKrok3({ email }));
        navigate(PODSUMOWANIE, { state: { id, pin } });
      }, 1500);
    },
    [permissions, email]
  );

  const send = useCallback(async () => {
    const values = Object.values(permissions);
    const permissionsChecked = values.reduce((prev, next) => prev && next);
    const validEmail = !isEmptyField(email) && validator.validate(email);

    if (
      permissionsChecked &&
      validEmail &&
      Boolean(recaptchaToken) &&
      formData
    ) {
      setSaving(true);

      const reportData = {
        contents: formData.comment || "",
        attachments: formData?.files ? formData.files : [],
        fullName: formData.anonymous ? undefined : formData.name || "",
        titleId: formData.type?.id || 0,
        closure: formData?.anonymous ? Closure.ANINIMOWE : Closure.JAWNE,
        email,
      };

      const { data, msg } = await postReport(reportData);

      if (data && !msg) {
        const { id, accessNumber } = data;
        onSend(id, accessNumber);
        return;
      }
      toast.error(msg);
      setSaving(false);
      setErrorId(ERRORS.api);
      return;
    }
    const checkForms = [
      {
        value: Boolean(recaptchaToken),
        id: ERRORS.recaptcha,
        errorTitle: "Uzupełnij reCAPTCHA",
      },
      {
        value: permissionsChecked,
        id: ERRORS.permissions,
        errorTitle: "Zaznacz wymagane pola",
      },
      {
        value: !isEmptyField(email),
        id: ERRORS.email,
        errorTitle: "Podaj email",
      },
      {
        value: validator.validate(email),
        id: ERRORS.email,
        errorTitle: "Nie poprawny adres email",
      },
    ];

    const formErrorId = checkForm(checkForms);

    setErrorId(formErrorId);
  }, [permissions, email, onSend, recaptchaToken, formData]);
  const comment = useSelector((state: RootState) => state.app.data?.comment);
  const type = useSelector((state: RootState) => state.app.data?.type);

  return (
    <Container
      noControll
      title="Formularz sygnalistów"
      description="Krok 3 - sprawdź poprawność danych oraz podaj adres email, na który otrzymasz aktualizację statusu i link do miejsca na ew. dalszą korespondencję. Mail nie zostanie ujawniony organizacji, której zgłoszenie dotyczy. "
    >
      <div className={classes.textContainer}>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div>
          <Typography variant="subtitle2">
            Podsumowanie twojego zgłoszenia
          </Typography>
          {/* Typ zgloszenia */}
          <Typography variant="subtitle1" className={classes.title}>
            {type ? type.name : ""}
          </Typography>
          <Typography variant="subtitle1">{comment}</Typography>
        </div>
      </div>
      <Input
        error={errorId === ERRORS.email}
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Twój email"
        className={classes.email}
      />
      <div className={classes.recaptcha}>
        <ReCAPTCHA
          size="normal"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
          onChange={(token) => {
            setRecaptchaToken(token);
          }}
        />
      </div>
      <div className={classes.permissions}>
        <CheckText checked={permissions[PERM1]} check={checkPermission(PERM1)}>
          <Typography variant="caption">
            Wyrażam niezbędną zgodę na przetwarzanie moich danych osobowych
            przez Invest It Sp. z o.o. (dalej: Administratora) w celu zawarcia
            bądź wykonania umowy realizacji usługi zapewniającej możliwość
            korzystania z jednego z serwisów Administratora w oparciu o treść
            regulaminu tego serwisu, której jestem stroną, w zakresie
            niezbędnych do realizacji tej umowy, jak również wypełnienia
            obowiązku prawnego ciążącego na Administratorze.
          </Typography>
        </CheckText>
        <CheckText checked={permissions[PERM2]} check={checkPermission(PERM2)}>
          <Typography variant="caption">
            Zapoznałem(-am) się z treścią{" "}
            <a
              href="https://www.investit.dev/polityka-prywatnosci-2/"
              target="_blank"
            >
              Klauzuli informacyjnej{" "}
            </a>
            oraz z
            <a
              href="https://www.investit.dev/polityka-prywatnosci-2/"
              target="_blank"
            >
              {`  Polityką Prywatności`}
            </a>
            , w tym z informacją o celu i sposobach przetwarzania danych
            osobowych oraz prawie dostępu do treści swoich danych i prawie ich
            poprawiania.
          </Typography>
        </CheckText>
        <CheckText checked={permissions[PERM3]} check={checkPermission(PERM3)}>
          <Typography variant="caption">
            Zapoznałem się z regulaminem serwisu sygnalisci.app oraz akceptuję
            jego treść.
          </Typography>
        </CheckText>
      </div>
      <div className={classes.controll}>
        <Button
          variant="contained"
          style={{ backgroundColor: palette.accent }}
          classes={{ contained: classes.submitButton }}
          onClick={send}
        >
          {saving ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            <Typography style={{ textTransform: "none" }}>
              Wyślij zgłoszenie
            </Typography>
          )}
        </Button>

        <Button
          className={classes.button}
          variant="text"
          onClick={() => navigate(KROK2)}
        >
          Wróć do edycji zgłoszenia
        </Button>
      </div>
    </Container>
  );
};

export default withRedirect(Krok3);
