import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { FormFile, ReportTitle } from "../types";

interface InitData {
  data?: {
    nip: string;
    name: string | null;
    comment: string;
    email: string;
    type: ReportTitle | null;
    files?: FormFile[];
    anonymous: boolean;
  };
  address: string;
}

const INIT: InitData = {
  data: undefined,
  address: "",
};

const INIT_KROK1_DATA = {
  nip: "",
  name: null,
  comment: "",
  email: "",
  type: null,
  files: [],
  anonymous: true,
};

const updateKrok1Action: CaseReducer<
  InitData,
  PayloadAction<{ nip: string; address: string }>
> = (state, action) => {
  const { nip, address } = action.payload;
  state.data = { ...INIT_KROK1_DATA, nip };
  state.address = address;
};

const setKrok2Action: CaseReducer<
  InitData,
  PayloadAction<{
    name: string | null;
    comment: string;
    type: ReportTitle | null;
    files: FormFile[];
    anonymous: boolean;
  }>
> = (state, action) => {
  const { name, comment, type, files, anonymous } = action.payload;
  if (!state.data) return;
  state.data.comment = comment;
  state.data.name = name;
  state.data.type = type;
  state.data.files = files;
  state.data.anonymous = anonymous;
};

const resetAction: CaseReducer<InitData> = (state, action) => {
  state.data = undefined;
};

const setKrok3Action: CaseReducer<
  InitData,
  PayloadAction<{ email: string }>
> = (state, action) => {
  const { email } = action.payload;
  if (!state.data) return;
  state.data.email = email;
};

const setAddressAction: CaseReducer<
  InitData,
  PayloadAction<{ address: string }>
> = (state, action) => {
  state.address = action.payload.address;
};

const slice = createSlice({
  name: "app",
  initialState: INIT,
  reducers: {
    updateKrok1: updateKrok1Action,
    setKrok2: setKrok2Action,
    setKrok3: setKrok3Action,
    reset: resetAction,
    setAddress: setAddressAction,
  },
});

export const { updateKrok1, setKrok2, setKrok3, reset, setAddress } =
  slice.actions;

export default slice.reducer;
