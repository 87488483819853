import React from "react";
import { Route, Routes } from "react-router-dom";
import Krok1 from "./Krok1";
import Krok2 from "./Krok2";

import "./index.scss";
import NoMatch from "./NoMatch";
import { EDIT, EDIT_LOGIN, KROK1, KROK2, KROK3, PODSUMOWANIE } from "./routes";
import Krok3 from "./Krok3";
import Logo from "./components/Logo";
import Podsumowanie from "./Podsumowanie";
import { Toaster } from "react-hot-toast";
import EditLogin from "./EditLogin";
import Edit from "./Edit";

function App() {
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="logo">
        <Logo />
      </div>
      <div className="App">
        <Routes>
          <Route path={KROK1} element={<Krok1 />} />
          <Route path={KROK2} element={<Krok2 />} />
          <Route path={KROK3} element={<Krok3 />} />
          <Route path={EDIT_LOGIN} element={<EditLogin />} />
          <Route path={EDIT} element={<Edit />} />
          <Route path={PODSUMOWANIE} element={<Podsumowanie />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
