import React from "react";
import { MOBILE_QUERY } from "../theme";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { KROK1 } from "../routes";

interface Props {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  nextPage?: () => void;
  cancel?: () => void;
  noControll?: boolean;
  loading?: boolean;
  noCancel?: boolean;
}

const useStyles = makeStyles({
  container: {
    width: "45rem",
    display: "flex",
    [MOBILE_QUERY]: {
      width: "90%",
    },
  },
  content: {
    display: "flex",
    paddingLeft: "100px !important",
    paddingRight: "100px !important",
    paddingTop: "85px !important",
    paddingBottom: "85px !important",
    width: "90%",
    [MOBILE_QUERY]: {
      padding: "20px !important",
    },
    flexDirection: "column",
  },
  title: {
    marginBottom: "30px !important",
    [MOBILE_QUERY]: {
      marginBottom: "20px !important",
    },
  },
  heading: {
    marginBottom: "20px !important",
  },
  controll: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
    [MOBILE_QUERY]: {
      marginTop: "10px",
      flexDirection: "column",
    },
  },
});

const Container = ({
  title,
  description,
  children,
  loading,
  nextPage,
  noControll,
  noCancel,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card className={classes.container}>
      <form
        style={{ display: "flex", flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          if (nextPage) nextPage();
        }}
      >
        <CardContent classes={{ root: classes.content }}>
          {(title || description) && (
            <div className={classes.heading}>
              <Typography variant="h3" classes={{ root: classes.title }}>
                {title}
              </Typography>
              <Typography variant="subtitle1">{description}</Typography>
            </div>
          )}
          {children}
          {!noControll && (
            <div className={classes.controll}>
              {!noCancel && (
                <Button
                  variant="text"
                  onClick={() => {
                    const result = window.confirm(
                      "Czy na pewno chcesz anulować?"
                    );
                    if (result) navigate(KROK1);
                  }}
                >
                  Anuluj
                </Button>
              )}
              <Button variant="outlined" onClick={nextPage}>
                {loading && <CircularProgress size={20} />}
                <Typography style={{ marginLeft: "01rem" }}>
                  Zapisz i przejdź dalej
                </Typography>
              </Button>
            </div>
          )}
        </CardContent>
      </form>
    </Card>
  );
};

export default Container;
