import React from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  name: string;
  onRemove: () => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px",
    alignItems: "center",
    width: "100%",
  },
  fileName: {
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px !important",
  },
});

export const AttachmentItem = ({ name, onRemove }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" className={classes.fileName}>
        {name}
      </Typography>
      <IconButton onClick={onRemove}>
        <Close />
      </IconButton>
    </div>
  );
};

export default AttachmentItem;
